import Logo from "../Assets/Logo.webp"
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { easeInOut, motion } from "framer-motion"
import { React, useEffect, useState } from 'react'


const navigation = [
  { name: ' ', href: ' ', current: true ,type:1},
  { name: 'HOME', href: '#', current: true ,type:0.9},
  { name: 'ABOUT', href: '#about', current: false ,type:0.8},
  { name: 'GALLERY', href: '#gallery', current: false ,type:0.7},
  { name: 'MERCHANDISE', href: '/store', current: false ,type:0.6},
  { name: 'PARTNERS', href: '#partners', current: false ,type:0.5},
  { name: 'LOCATION', href: '#location', current: false ,type:0.4},
  { name: 'CONTACT', href: '#contact', current: false ,type:0.3},
]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}






export default function NavBar() {
  
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-auto px-2 sm:px-6 lg:px-8 sm:py-10 py-5">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute sm:inset-y-0 inset-y-3 right-1 items-end">
                {/* Mobile menu button*/}
                <Disclosure.Button type="submit" className="z-50 relative inline-flex items-center justify-center hover:text-gray-500 ">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6 text-gray-800 "
                      onClick={
                        document.body.style.overflow = 'hidden'
                      }
                      aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6 text-white "
                      onClick={
                        document.body.style.overflow = ''
                      }
                      aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>


              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href=" #"><img
                    className="sm:h-28 h-20 w-auto "
                    src={Logo}
                    alt="SimblySouth"
                  /></a>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="font-thin scroll-mx-11 text-center">
            <div id="NavBarAnimate" className="h-full w-full fixed top-0 left-0 flex flex-col justify-center items-center z-40">

              <div className="">
                {navigation.map((item) =>
              
                    (

                      <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: item.type,delay:0.4, ease: easeInOut }}
                      >
                        <Disclosure.Button

                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current ? ' text-gray-800' : ' text-gray-800 hover:text-gray-700',
                            'block rounded-md px-3 py-1 text-5xl font-sofia font-bold'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      </motion.div>
                    )
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
