import { Fragment, useRef, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IoHeartOutline } from "react-icons/io5";
import list from "../data.js"

import React from 'react'
import productList from '../Functions/Context.jsx';



export const GridList = () => {

    const cancelButtonRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState()
    const [cart, setCart] = useContext(productList)



    const handleSubmit = (Item) => {
        setOpen(true)
        setItem(Item)
    }

    const AddtoCart = (Item, e) => {
        e.preventDefault();
        let carts = cart.find(x => x.image === Item.image)
        if (carts) return alert("Product Already in the cart");
        setCart([...cart, { id: Date.now(), name: Item.item, type: Item.type, image: Item.image, quantity: 1 }])
        alert("Product added to Cart")
    }


    useEffect(() => {
        let carts = JSON.parse(localStorage.getItem('cart'))
        if (carts && carts.length > 0) {
            setCart(carts)
        }
    }, [])

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <h3 className=' text-gray-700 font-sofia font-thin text-md'>{str}</h3>);
        return newText;
    }




    return (
        <div className='h-fit'>

            <div className='w-full flex flex-col items-center justify-center py-7 text-center gap-5' id="productlist">
                <div className='flex gap-3'>
                    <a href='http://www.facebook.com/simblysouthdxb'><button>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-7 w-7 text-gray-700"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path
                                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                        </svg>
                    </button></a>

                    <a href="http://www.instagram.com/simblysouth" className='hover:text-gray-200'><button>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-7 w-7 text-gray-700"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path
                                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                    </button></a>

                    <a href="http://www.twitter.com/simblysouth">
                        <button>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-7 w-7 text-gray-700"
                                fill="currentColor"
                                viewBox="0 0 512 512">
                                <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                            </svg>
                        </button>
                    </a>

                    <a href="https://www.tiktok.com/@simblysouthdxb">
                        <button>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                fill="currentColor"
                                class="h-7 w-7 text-gray-700">
                                <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                            </svg>
                        </button>
                    </a>
                </div>
                <h1 className=' w-5/6 text-2xl font-mono font-semibold text-gray-700'>AGED SOUTHERN AESTHETICS COLLECTIONS</h1>
            </div>
            <div className='flex items-center justify-center py-3 sm:py-5'>
                <div class="grid lg:grid-cols-3 grid-cols-2 lg:gap-44 md:gap-40 gap-5 gap-y-24 text-center">

                    {
                        list.map((items) => (
                            <>
                                <div className='sm:h-72 sm:w-64 h-64 w-40'>
                                    <button type="button" onClick={() => {
                                        handleSubmit(items)
                                    }
                                    } >
                                        <div>
                                            <div className=' bg-white h-fit'>
                                                <img src={require(`../../Assets/P${items.image}.webp`)} className='sm:hover:scale-[1.5] transition-all ease-in-out' alt='SS' />
                                            </div>
                                            <div className='py-5'>
                                                <h1 className=' text-gray-800 font-josefin font-semibold text-xl'>{items.item}</h1>
                                                <NewlineText text={items.description} />
                                                <p className=' text-gray-500 font-josefin font-thin text-sm'>{items.type}</p>

                                            </div>
                                        </div>
                                    </button>
                                    <div className='items-center justify-center hidden sm:flex'>
                                        <button type='button' onClick={(e) => {
                                            AddtoCart(items, e)
                                        }}>
                                            <IoHeartOutline className=' text-3xl sm:my-3 -my-5 hover:text-red-600' />
                                        </button>
                                    </div>
                                    
                                </div>
                            </>
                        ))
                    }


                </div>

            </div>
            <div className=' sm:h-44' />





            {open ?
                <>
                    <Transition.Root show={open} as={Fragment}>
                        <div className="fixed inset-0 z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>



                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="fixed inset-0 z-10 flex items-center justify-center">
                                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <img src={require(`../../Assets/P${item.image}.webp`)} alt='SS' />
                                            </div>
                                        </div>
                                        <div className="bg-white sm:px-6 px-3 py-3 flex flex-row-reverse gap-3">

                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-2xl bg-black px-3 py-2 sm:text-sm text-xs font-semibold text-white shadow-sm hover:bg-gray-900 sm:ml-3 sm:w-auto"
                                                onClick={(e) => {

                                                    setOpen(false)
                                                    AddtoCart(item, e)
                                                }}
                                            >
                                                Add to Cart
                                            </button>

                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-2xl bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>


                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>


                        </div>
                    </Transition.Root>
                </>
                :
                <>
                </>}

                <div className='h-16 w-full sm:hidden'/>
        </div >
    )
}
