import React from 'react'
import { Suspense, lazy } from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Partners from './Pages/Partners';
import Gallery from './Pages/Gallery';
import Merchandise from './Pages/Merchandise';
import Map from './Pages/Map';
import Footer from './Pages/Footer';
import VisibleBar from './Components/VisibleBar';



const Main = () => {
    return (
        <div className=' bg-black'>
            <VisibleBar/>
            <Home />
            <About />
            <Gallery />
            <Merchandise />
            <Partners />
            <Map />
            <Footer />
        </div>
    )
}

export default Main





