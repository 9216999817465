import Logo from "../../Assets/LogoBlack.webp"
import { IoHomeOutline, IoTrashSharp, IoCartOutline } from "react-icons/io5";
import { React, useEffect, useState, useContext, useRef } from 'react'
import LogoMobile from '../../Assets/LogoBlack-Mobile.webp'
import { Disclosure } from '@headlessui/react'
import productList from "../Functions/Context";
import { IoCloseSharp } from "react-icons/io5";
import NavBooleon from '../Functions/Booleon';


export default function NavBar() {

    const navbarRef = useRef(null);
    const modalRef = useRef(null);
    const [cart, setCart] = useContext(productList)
    const [close, setClose] = useState(false)


    const deleteOrder = async (Order) => {
        await setCart(cart.filter(o => {
            if (o.image === Order.image) {
                return false
            }
            return true
        }))
    }

    const IncrementOrder = (Item, e) => {
        e.preventDefault();
        if (Item.quantity == 5) return
        setCart((Items) => {
            const index = Items.findIndex(x => x.image === Item.image);
            const updatedItem = {
                ...Items[index],
                quantity: Item.quantity + 1
            }

            return [
                ...Items.slice(0, index),
                updatedItem, // --> Updating the item, instead of creating new one
                ...Items.slice(index + 1)
            ]
        });
    }

    const DecrementOrder = (Item, e) => {
        e.preventDefault();
        if (Item.quantity == 1) return
        setCart((Items) => {
            const index = Items.findIndex(x => x.image === Item.image);
            const updatedItem = {
                ...Items[index],
                quantity: Item.quantity - 1
            }

            return [
                ...Items.slice(0, index),
                updatedItem, // --> Updating the item, instead of creating new one
                ...Items.slice(index + 1)
            ]
        });
    }

    const EnquireNow = (e) => {
        e.preventDefault();

        let message = [];
        let index = 1;
        for (let item of cart) {
            message.push(`${index++}. *${item.name}* - _${item.type}_ : (${item.quantity}x)`);
        }
        window.location.href = `whatsapp://send/?phone=+971581504254&text=${message.join("%0")}`
    }

    const NavBarVisibility = () => {
        if (window.scrollY > 100) {
            setClose(false)
        } else {
            setClose(true)
        }
    }

    const hideModalHandler = (e) => {
        e.preventDefault();
        modalRef.current?.click();
        setClose(!close)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {

            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                modalRef.current?.click();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    useEffect(() => {

        window.addEventListener("scroll", NavBarVisibility)

        return () => {
            window.removeEventListener("scroll", NavBarVisibility)
        }
    }, [close])

    return (
        <>
            <Disclosure as="nav" className="" ref={navbarRef}>
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-auto px-2 sm:px-6 lg:px-8 sm:py-10 py-5">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="absolute sm:inset-y-0 inset-y-3 right-1 items-end">
                                    {/* Mobile menu button*/}
                                    <div className="flex sm:text-3xl text-2xl gap-5 text-white bg-gray-700 p-2 rounded-full">
                                        {/* <div className=" bg-red-600 rounded-full sm:h-5 sm:w-5 h-4 w-4 -mx-5 my-1 text-white font-montserrat sm:text-sm text-xs items-center justify-center text-center ">{cart.length}</div>
                                        <div >
                                            <a ><Disclosure.Button ref={modalRef} type="submit" className=" hover:text-gray-600 text-4xl -my-0.5">
                                                {open && close ? (
                                                    <IoCartOutline aria-hidden="true" />
                                                ) : (
                                                    <IoCartOutline aria-hidden="true" />
                                                )}

                                            </Disclosure.Button></a>
                                        </div> */}
                                        <a href="/" className="hover:text-gray-400"><h1><IoHomeOutline /></h1></a>
                                    </div>
                                </div>


                                <div className="flex flex-1 items-stretch justify-start">
                                    <div className="flex flex-shrink-0 items-center">
                                        <a href="#"><img
                                            className="sm:h-28 sm:block hidden w-auto "
                                            src={Logo}
                                            alt="SimblySouth"
                                        /></a>
                                        <a href="#"><img
                                            className="h-20 sm:hidden w-auto "
                                            src={LogoMobile}
                                            alt="SimblySouth"
                                        /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {close ? (
                            <Disclosure.Panel className="font-thin scroll-mx-11 text-center">
                                {
                                    cart.length > 0 ? (
                                        <div className=" h-96 w-80 fixed right-5 sm:-my-16 -my-5 z-40 bg-white rounded-3xl shadow-2xl">
                                            <div className="flex py-4 items-center justify-center font-bold font-montserrat text-black text-2xl">
                                                <h1 className='mx-14'>Cart Items</h1>
                                                <IoCloseSharp aria-hidden="true" onClick={hideModalHandler} />
                                            </div>
                                            <div className="h-64 overflow-auto">
                                                {
                                                    cart.map((items) => (

                                                        <>
                                                            <div className=" h-24 px-3">
                                                                <div className="flex">

                                                                    <div className="">
                                                                        <img className="h-24 p-2" src={require(`../../Assets/P${items.image}.webp`)} />
                                                                    </div>

                                                                    <div className=" text-left font-sofia py-3">
                                                                        <h1 className=" font-bold">{items.name}</h1>
                                                                        <p1>{items.type}</p1>
                                                                        <div>
                                                                            <div class=" px-3 rounded-lg" data-hs-input-number>
                                                                                <div class="w-full flex justify-between items-center gap-x-3">
                                                                                    <div class="grow">
                                                                                        <input class="w-5 p-0 bg-transparent border-0 text-gray-800" type="text" value={items.quantity} data-hs-input-number-input />
                                                                                    </div>
                                                                                    <div class="flex justify-end items-center gap-x-2">

                                                                                        <button type="button" class="size-5 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border hover:text-gray-700" data-hs-input-number-decrement
                                                                                            onClick={(e) => DecrementOrder(items, e)}
                                                                                        >
                                                                                            <svg class="flex-shrink-0 size-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /></svg>
                                                                                        </button>

                                                                                        <button type="button" class="size-5 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border hover:text-gray-700" data-hs-input-number-increment
                                                                                            onClick={(e) => IncrementOrder(items, e)}
                                                                                        >
                                                                                            <svg class="flex-shrink-0 size-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="text-2xl p-5">
                                                                        <button type="button" onClick={() => deleteOrder(items)} >
                                                                            <IoTrashSharp />
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }

                                            </div>

                                            <div className="flex items-end justify-end m-3 sm:gap-0 gap-3">
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-2xl bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                                                    onClick={() => setCart([])}
                                                >
                                                    Clear All
                                                </button>

                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-2xl bg-black px-3 py-2 sm:text-sm text-xs font-semibold text-white shadow-sm hover:bg-gray-800 sm:ml-3 sm:w-auto"
                                                    onClick={(e) => EnquireNow(e)}
                                                >
                                                    Enquire Now
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className=" h-20 w-80 fixed right-5 sm:-my-16 -my-5 z-40 bg-white rounded-3xl shadow-2xl font-josefin items-center justify-center flex">
                                            <h1 className="text-sm sm:text-md">There are no Items to show in your cart</h1>
                                            <IoCloseSharp aria-hidden="true" onClick={hideModalHandler} className="w-10 text-2xl" />
                                        </div>

                                    )
                                }

                            </Disclosure.Panel>
                        ) :
                            <Disclosure.Panel> {({ close }) => (close())}</Disclosure.Panel>
                        }



                    </>
                )}
            </Disclosure>
        </>
    )
}
