const list = [
    {
        "item": "ALLIYA",
        "description":"I'm the sorry alliya",
        "type": "Over-Sized Tee",
        "image": "1"
    },
    {
        "item": "BEEF FRY",
        "description":"Only BDF\n No LDF or UDF",
        "type": "Over-Sized Tee",
        "image": "2"
    },
    {
        "item": "MEOW",
        "description":"Easily bribed with treats",
        "type": "Over-Sized Tee",
        "image": "3"
    },
    {
        "item": "ALLIYA",
        "description":"I'm the sorry alliya",
        "type": "Comfort Tee",
        "image": "4"
    },
    {
        "item": "BEEF FRY",
        "description":"Only BDF\n No LDF or UDF",
        "type": "Comfort Tee",
        "image": "5"
    },
    {
        "item": "MEOW",
        "description":"Easily bribed with treats",
        "type": "Comfort Tee",
        "image": "6"
    },
    {
        "item": "FORD VS FERRARI",
        "description":"There's a point at 7,000 RPM... where everything fades.",
        "type": "Over-Sized Tee",
        "image": "7"
    },
    {
        "item": "BASOREXIA",
        "description":"Your lips are my sanctury",
        "type": "Over-Sized Tee",
        "image": "8"
    },
    {
        "item": `VINTAGE FRIENDS`,
        "description":"They don't know that we know they know we know!",
        "type": "Over-Sized Tee",
        "image": "9"
    },
    {
        "item": "FORD VS FERRARI",
        "description":"There's a point at 7,000 RPM... where everything fades.",
        "type": "Comfort Tee",
        "image": "10"
    },
    {
        "item": "BASOREXIA",
        "description":"Your lips are my sanctury",
        "type": "Comfort Tee",
        "image": "11"
    },
    {
        "item": `VINTAGE FRIENDS`,
        "description":"They don't know that we know they know we know!",
        "type": "Comfort Tee",
        "image": "12"
    },
    {
        "item": "VIBEOWSKY",
        "description":"Lost in the music, found in the vibe",
        "type": "Over-Sized Tee",
        "image": "13"
    },
    {
        "item": "SIMBLY DANCE",
        "description":"Aattam - Kondattam like nobody's watching",
        "type": "Comfort Tee",
        "image": "14"
    },
    {
        "item": "SIMBLY DANCE",
        "description":"Aattam - Kondattam like nobody's watching",
        "type": "Over-Sized Tee",
        "image": "15"
    },
    {
        "item": "SOUTHERN SECRETS LTD EDITION COASTERS",
        "description":"Cheers to funky sips, with no drips",
        "type": "Coasters",
        "image": "16"
    }
];

export default list