import React from 'react'
import Event1 from "../Assets/Event1.webp"


const About = () => {
    return (
        <div className='h-fit' id="about">
            <div className='w-screen h-fit py-10 bg-slate-100 items-center justify-center flex'>
                <h1 className=' font-light text-4xl sm:text-7xl'>ABOUT US</h1>
            </div>
            <div className='h-fit bg-fixed bg-center bg-[url("../Assets/About.webp")] text-white items-center justify-center flex'>
                <div className='h-fit bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] flex items-center justify-center'>
                    <div className='sm:w-4/6 w-5/6 py-14 flex flex-col gap-8'>
                        <h2 className=' font-sofia text-md sm:text-2xl text-center leading-normal'>
                            Mango Dolly and Rascala, walking hand in hand, encountered the need to bring to the world an
                            evening of paradise coupled with dravidian music that would rock the week bygone and just like the nerve crushing, skull rocking entry of any hero…. SIMBLY SOUTH was Birthed in 2013!!
                            - SIMBLY put as paradise on earth, “Swargam is here!”

                            <br />
                            <br />
                            The dashing combo of Lalettan with the pinch of Rajni and class of Kamal, is unbearably hot and happening here and mind you … it happens just SIMBLY, just like that -HERE. Almost like the bygone era of the Lord of Music, Roshni & Jim welcomes you to their piece of Swargam with Madhyam, Touchings and Music, “you get it all – from the Rampurs and the Mylapore, Whiskey to Cocktails, BDF to Chicken 65 savour it all here.’’
                            <br />
                            <br />
                            The venue is divided into a chill island, bar buzz, dance floor mayhem & exclusive boiler room VVIP scene. Just when the palette in the brain gets responding to good music, the simble touchings sets your mind on a high horse. Whatever the line-up and whatever the theme, Simbly South night is always a unruly blaze of South Asian cross-genre and cross-continental glory.
                            <br />
                            <br />
                            We definitely aren't your ordinary nightclub, and this Award Winning Best Asian Club Night has a different take on the standard night out that makes it wildly popular with south indian celebrities from actor Tovino Thomas to music director Anirudh Ravichander.
                            <br />
                            <br />
                            Its an absolute intimate, quirky yet classy night, with a track record of a decade as a Pan Asian Classic.
                            <br />
                            <br />
                            Let's do this, Macchi!
                        </h2>

                        <div className='w-full justify-center items-center text-center flex gap-5 '>
                            <a href='http://www.facebook.com/simblysouthdxb'><button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-7 w-7 text-white"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                                </svg>
                            </button></a>

                            <a href="http://www.instagram.com/simblysouth" className='hover:text-gray-200'><button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-7 w-7 text-white"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </button></a>

                            <a href="http://www.twitter.com/simblysouth">
                                <button>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-7 w-7 text-white"
                                        fill="currentColor"
                                        viewBox="0 0 512 512">
                                        <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                                    </svg>
                                </button>
                            </a>

                            <a href="https://www.tiktok.com/@simblysouthdxb">
                                <button>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        fill="currentColor"
                                        class="h-7 w-7 text-white">
                                        <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>


                </div>
            </div>
            <div className='w-screen  h-fit bg-neutral-900'>
                <div className='h-full w-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] sm:flex items-center justify-center py-12'>
                    <div className='flex flex-col items-center justify-center p-3 sm:p-0'>
                        <img src={Event1} className='w-auto sm:h-36' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About