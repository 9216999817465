import React, { useEffect } from 'react'
import { HomePage } from './Pages/HomePage'
import { GridList } from './Pages/GridList'
import Footer from './Pages/Footer'
import { useState } from 'react'
import productList from './Functions/Context'
import VisibleBar from './Components/VisibleBar'
import NavBooleon from './Functions/Booleon';

const StorePage = () => {
    const [cart, setCart] = useState([])
    const [close, setClose] = useState(false)

    useEffect(() => {
        document.title = "Shop Now - SimblySouth"
    }, []);


    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart])

    return (
        <div className=' bg-gray-300'>
            <productList.Provider value={[cart, setCart]}>
                <NavBooleon.Provider value={[close, setClose]}>
                    <VisibleBar />
                    <HomePage />
                    <GridList />
                    <Footer />
                </NavBooleon.Provider>
            </productList.Provider>
        </div>
    )
}

export default StorePage