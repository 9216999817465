import React from 'react'
import Sponser1 from '../Assets/S1.webp'
import Sponser2 from '../Assets/S2.webp'
import Sponser3 from '../Assets/S3.webp'
import Sponser4 from '../Assets/S4.webp'
import Event4 from "../Assets/Event4.webp"


const Partners = () => {
    return (
        <div className='h-fit bg-zinc-800' id="partners">
            <div className='w-screen h-fit sm:py-16 py-10 bg-slate-100 items-center justify-center flex'>
                <h1 className=' font-light text-4xl sm:text-7xl'>PARTNERS</h1>
            </div>
            <div className='h-fit bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] flex items-center justify-center'>
                <div className='sm:flex justify-center items-center p-14 gap-2 hidden gap-6'>
                    <img src={Sponser1} className=' lg:h-40 md:h-28 h-24 w-auto' />
                    <img src={Sponser2} className='lg:h-40 md:h-28 h-24 w-auto' />
                    <img src={Sponser3} className=' lg:h-40 md:h-28 h-24 w-auto' />
                    <img src={Sponser4} className='lg:h-40 md:h-28 h-24 w-auto' />
                </div>
                <div className='sm:hidden justify-center items-center p-8 flex flex-col gap-7'>
                    <img src={Sponser1} className=' h-60 w-auto' />
                    <img src={Sponser2} className='h-60 w-auto' />
                    <img src={Sponser3} className=' h-48 w-auto' />
                    <img src={Sponser4} className='h-58 w-auto' />
                </div>
            </div>

            <div className='w-screen h-fit bg-neutral-900'>
                <div className='h-full w-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] sm:flex items-center justify-center py-2'>
                    <div className='flex flex-col items-center justify-center py-2'>
                        <img src={Event4} className='w-auto h-32 sm:h-44' />
                        <div className='m-3 w-auto h-auto border-2 justify-center items-center text-center'>
                            <h1 className=' text-slate-100 font-josefin sm:text-xl text-sm m-2'>260+ VISITS</h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Partners