import React from 'react'


const Map = () => {
    return (
        <div className=' sm:h-650 h-fit bg-zinc-800 ' id="location">
            <div className='h-full sm:flex'>
                <div className='sm:w-1/2 sm:h-full h-[400px]' >
                    <iframe className='w-full h-full grayscale invert-[0.92] contrast-[0.83]'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.064097209112!2d55.323263399999995!3d25.234766000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f694db4777b19%3A0x1b068e5b802ca38f!2sSimbly%20South!5e0!3m2!1sen!2sin!4v1702224472011!5m2!1sen!2sin"></iframe>
                </div>
                <div className='sm:w-1/2 items-center justify-center flex'>
                    <div className=' text-left h-4/6 w-4/6 flex flex-col sm:gap-7 gap-5 my-10 sm:my-0'>
                        <h1 className=' sm:text-6xl text-4xl  font-thin text-white'>EVE PENTHOUSE & LOUNGE - LEVEL 34</h1>
                        <div>
                            <p className=' text-white font-extralight'>HYATT REGENCY CREEK HEIGHTS</p>
                            <p className=' text-white font-extralight'>HEALTHCARE CITY</p>
                        </div>
                        <a href='https://maps.app.goo.gl/mXD7MjRTwHgFzSHL9'
                            className=' text-yellow-500 font-extralight text-2xl hover:underline hover:text-white'>GET DIRECTIONS</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Map