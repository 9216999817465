import React from 'react'
import Event2 from "../Assets/Event2.webp"


const Gallery = () => {
  return (
    <div className='h-fit' id="gallery">
      <div className='w-screen h-fit bg-slate-100 items-center justify-center flex flex-col py-10'>
        <div className='sm:w-4/6 items-center justify-center flex flex-col text-center'>
          <h1 className=' font-light text-4xl sm:text-7xl'>ELEVATE YOUR VVIP EXPERIENCE OR ORGANIZE A CHIC GROUP BOOKING </h1>
         
        </div>
      </div>
      <div className=''>


        <div class='grid grid-cols-1 md:grid-cols-3'>

          <div id='GalleryItems' className=' bg-[url("../Assets/G1.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>VVIP SUITES</h1>
            </div>
          </div>

          <div id='GalleryItems' className=' bg-[url("../Assets/G2.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>BAR</h1>
            </div>
          </div>

          <div id='GalleryItems' className=' bg-[url("../Assets/G3.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center text-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>DANCE FLOOR</h1>
            </div>
          </div>

          <div id='GalleryItems' className=' bg-[url("../Assets/G4.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center text-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>MUSIC</h1>
            </div>
          </div>

          <div id='GalleryItems' className=' bg-[url("../Assets/G5.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>ACTS</h1>
            </div>
          </div>

          <div id='GalleryItems' className=' bg-[url("../Assets/G6.webp")] bg-center bg-cover sm:h-420 sm:w-420 h-420 w-full'>
            <div className='h-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:2px_2px] flex items-center justify-center text-center'>
              <h1 className='font-thin text-6xl text-white absolute z-0'>VIBE</h1>
            </div>
          </div>

        </div>

      </div>

      <div className='h-fit bg-neutral-900'>
        <div className=' bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] sm:flex items-center justify-center py-5'>
          <div className='flex flex-col items-center justify-center py-5'>
            <img src={Event2} className='w-auto h-32 sm:h-44' />
            <div className='h-5'/>
              <div className=' w-auto h-auto border-2 justify-center items-center text-center my-2'>
                <h1 className=' text-slate-100 font-josefin sm:text-xl text-sm m-2'>Wanna check our Store ?</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery