
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import Main from './Main';
import StorePage from './Store/StorePage';
import ErrorPage from './Pages/ErrorPage';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route >
        <Route path='*' element={<ErrorPage />}/>
        <Route index element={<Main />} />
        <Route path='/store' element={<StorePage />}/>
      </Route>
    )
  )
  return (

    <RouterProvider router={router} />

  );
}

export default App;
