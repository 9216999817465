import React from 'react'
import NavBar from '../Components/NavBar'
import { BiDownArrowAlt } from "react-icons/bi";

export const HomePage = () => {
    return (
        <div className='h-screen bg-cover bg-center bg-[url("../Assets/StoreHomePage.webp")] '>
            <NavBar />
            <div className=' absolute bottom-1 right-5'>
                <a href='#productlist'><BiDownArrowAlt className=' animate-bounce sm:text-8xl text-6xl text-gray-700' /></a>
            </div>
        </div>
    )
}
