import React from 'react'
import Event3 from "../Assets/Event3.webp"



const Merchandise = () => {
    return (
        <div className='h-fit' id="merchandise">
            <div className='w-screen h-fit bg-slate-100 items-center justify-center flex flex-col py-10'>
                <div className='w-4/6 items-center justify-center flex flex-col text-center'>
                    <h1 className=' font-light text-4xl sm:text-7xl'>AGED SOUTHERN AESTHETICS <span className=' italic'>LTD EDITION</span></h1>
                </div>
            </div>

            <div className='bg-cover bg-center bg-[url("../Assets/Merchant.webp")]  h-600'>
                <div className='h-full w-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] flex items-center justify-center sm:gap-80'>
                    <a href='/store'><h1 className='font-sofia text-yellow-500 sm:text-7xl text-6xl text-center hover:text-yellow-300'>Visit our Merchandise Store !</h1></a>
                </div>
            </div>

            <div className='w-screen h-fit bg-neutral-900'>
                <div className='h-full w-full bg-[url("../Assets/dot.png")] bg-repeat bg-[length:7px_2px] sm:flex items-center justify-center'>
                    <div className='flex flex-col items-center justify-center py-7'>
                        <img src={Event3} className='w-auto h-32 sm:h-44' />
                        <div className='h-5' />
                        <div className='my-2 w-auto h-auto border-2 justify-center items-center text-center'>
                            <h1 className=' text-slate-100 font-josefin sm:text-xl text-sm m-2'>#IYKYK</h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Merchandise